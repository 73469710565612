<template>
    <div>
        <st-data-table
            :items="rows"
            :fields="fields"
            :actions="actions"
            :modelPresenter="presenter"
            @view="onView"
            @download="onDownload"
            responsive="sm"
            hover
        >
        </st-data-table>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { IssuedDocumentModel } from '@/modules/issued-document/issued-document-model';

const { fields } = IssuedDocumentModel;

export default {
    name: 'IssuedDocumentListTable',
    data() {
        return {
            presenter: IssuedDocumentModel.presenter,
            actions: [
                {
                    name: 'view',
                    icon: 'eye',
                    tooltipText: this.$t('GENERAL.ACTIONS.VIEW'),
                    customIcon: false,
                    type: 'primary'
                },
                {
                    name: 'download',
                    icon: 'file-download',
                    tooltipText: this.$t('GENERAL.ACTIONS.DOWNLOAD'),
                    customIcon: false,
                    type: 'primary'
                }
            ],
        };
    },
    computed: {
        ...mapGetters({
            rows: 'issuedDocument/list/rows',
            fileDownloadAccessToken: 'issuedDocument/list/fileDownloadAccessToken',
        }),
        fields() {
            return [
                fields.issuer_user_full_name,
                fields.issued_date.extendField({ bold: true }),
                fields.document_series,
                fields.document_name.extendField({ bold: true }),
                fields.beneficiary_full_names,
            ];
        }
    },
    methods: {
        ...mapActions({
            downloadFile: 'shared/downloadFile',
        }),
        onView(doc) {
            window.open(`${window.VUE_APP_API_URL}/files/${doc.item.file_id}?token=${this.fileDownloadAccessToken}`, '_blank');
        },
        onDownload(doc) {
            const url = `${window.VUE_APP_API_URL}/files/${doc.item.file_id}?token=${this.fileDownloadAccessToken}`;
            this.downloadFile(url);
        }
    },
};
</script>
