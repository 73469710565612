
import RelationToOneField from '@/shared/fields/relation-to-one-field';
import { IssuedDocumentService } from '@/modules/issued-document/issued-document-service';

export class IssuedDocumentTypeField {
    static relationToOne(name, label, rules, filterParams) {
        return new RelationToOneField(
            name,
            label,
            IssuedDocumentService.getDocumentTypeList,
            (record) => {
                if (!record) {
                    return '';
                }
                return {
                    ...record,
                    value: record.document_name,
                    text: record.document_name,
                };
            },
            rules,
            filterParams,
        );
    }
}
